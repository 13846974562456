@tailwind base;
@tailwind components;
@tailwind utilities;
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";
@import "primereact/resources/themes/tailwind-light/theme.css";

@layer {
  .p-button {
    @apply focus:!shadow-none;
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close:focus,
  .p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
    @apply focus:!shadow-none;
  }
}

.breadcrumb > ul > li > a > span, .p-breadcrumb-chevron{
  color: white !important;
}
